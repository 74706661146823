import { render, staticRenderFns } from "./GrupoAcessorioGrid.vue?vue&type=template&id=8cd547ae&"
import script from "./GrupoAcessorioGrid.js?vue&type=script&lang=js&"
export * from "./GrupoAcessorioGrid.js?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QTr from 'quasar/src/components/table/QTr.js';
import QTd from 'quasar/src/components/table/QTd.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QTr,QTd});
